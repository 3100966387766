import React from 'react'

import { Layout } from './layout'
import FullArticle from './fullArticle'
import NotFoundPage from '../pages/404'
import { usePreview } from '../hooks'
import { Article } from '../types'
import { TemplateWrapper } from './templateWrapper'

type ArticlePreviewPageProps = {
  params: {
    "*": string;
  }
}

const ArticlePreviewPage: React.FC<ArticlePreviewPageProps> = (props) => {
  const { params } = props
  const { loading, error, data } = usePreview<Article>(params['*'])

  if (!data && !loading) return <NotFoundPage />
  return (<Layout>
    <TemplateWrapper>
      <FullArticle article={data && data.getContentPreview} pendingMessage={error && error.message} />
    </TemplateWrapper>
  </Layout>
  )
}

export default ArticlePreviewPage
